import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { formatBytes, getFileFormat } from 'helpers';
import { DOCUMENT_ALLOWED_FILETYPES } from 'helpers/documentConstants';
import { setFileLoading } from 'stores/me';

import Button from 'components/Button';
import Typography from 'components/Typography';
import Attachment from 'components/Svg/Attachment';
import FileBadge from 'components/FileBadge';
import SingleSkeleton from 'components/Skeletons/SingleSkeleton';

import { colorName, colors } from 'styles';
import {
  DropzoneBox,
  FileLabel,
  IconWrapper,
  ProgressText,
  ProgressWrapperSkeleton,
} from '../style';
import { messages } from '../messages';
import ConfirmUploadModal from './ConfirmUploadModal';

const defaultPropTypes = {
  fileTypes: DOCUMENT_ALLOWED_FILETYPES,
  acceptableHint: '.jpg, .png, .docx, .xls, .pdf, .msg, audio',
  maxSize: 5e6,
  multipleDrop: false,
  maxFilesOnDrop: 1,
};

const propTypes = {
  fileTypes: PropTypes.array,
  acceptableHint: PropTypes.string,
  maxSize: PropTypes.number,
  multipleDrop: PropTypes.bool,
  maxFilesOnDrop: PropTypes.number,
  addFile: PropTypes.func,
  isChatUploader: PropTypes.bool,
  confirmationModal: PropTypes.bool,
};

const Dropzone = ({
  fileTypes,
  acceptableHint,
  maxSize,
  multipleDrop,
  maxFilesOnDrop,
  addFile,
  isChatUploader,
  confirmationModal = true,
  ...props
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const accept = fileTypes.join(', ');
  const maxSizeStr = formatBytes(maxSize, 0);
  const { isFileUploading } = useSelector((state) => state.me);
  const [errorNotice, setErrorNotice] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const confirmUpload = useCallback(
    (files) => {
      setIsConfirmModalOpen(false);
      if (multipleDrop && files.length > maxFilesOnDrop) {
        setErrorNotice(
          intl.formatMessage(messages.maxFileNumberIs, { number: maxFilesOnDrop })
        );
      } else {
        dispatch(setFileLoading(true));
        setIsUploading(true);
        addFile && addFile(files);
        setFiles(files);
        setIsUploading(false);
        setAcceptedFiles([]);
      }
    },
    [addFile, dispatch, intl, maxFilesOnDrop, multipleDrop]
  );

  const onDropRejected = useCallback(
    (files) => {
      if (files.length > 1) {
        setErrorNotice(intl.formatMessage(messages.maxFileNumberIs, { number: '1' }));
      } else if (files[0].size > maxSize) {
        setErrorNotice(
          intl.formatMessage(messages.maxFileUploadSizeIs, { size: maxSizeStr })
        );
      } else {
        setErrorNotice(intl.formatMessage(messages.invalidFileType));
      }
    },
    [maxSizeStr, maxSize, intl]
  );

  const onDropAccepted = useCallback(
    (files) => {
      if (confirmationModal) {
        if (files.length) {
          setIsConfirmModalOpen(true);
          setAcceptedFiles(files);
        }
      } else {
        if (files.length) {
          confirmUpload(files);
        }
      }
    },
    [confirmationModal, confirmUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    multiple: multipleDrop,
    parallelUploads: 1,
    onDropRejected,
    onDropAccepted,
  });

  return (
    <>
      {(isUploading && !isChatUploader) || (isFileUploading && !isChatUploader) ? (
        <DropzoneBox>
          <Typography size="small" bottomMargin={16}>
            <ProgressText>{intl.formatMessage(messages.uploading)}</ProgressText>

            {files &&
              files.map(({ name }) => (
                <FileLabel key={name}>
                  <FileBadge>{getFileFormat(name)}</FileBadge>

                  {name}
                </FileLabel>
              ))}
          </Typography>

          <ProgressWrapperSkeleton>
            <SingleSkeleton height={16} />
          </ProgressWrapperSkeleton>
        </DropzoneBox>
      ) : (
        <DropzoneBox
          isChatUploader={isChatUploader}
          {...getRootProps({ isDragActive, ...props })}
        >
          <input {...getInputProps()} />

          {isChatUploader ? (
            <Attachment />
          ) : (
            <>
              <IconWrapper>
                <Attachment />
              </IconWrapper>

              <Typography size="small" bottomMargin={12}>
                {intl.formatMessage(messages.dragAndDrop)}
              </Typography>

              <Button secondary>{intl.formatMessage(messages.selectFromDrive)}</Button>
            </>
          )}
        </DropzoneBox>
      )}

      {!isChatUploader && (
        <>
          <Typography
            size="small"
            color={colors.mediumGray}
            bottomMargin={!errorNotice ? 16 : 0}
            textAlign="right"
          >
            {`${intl.formatMessage(messages.acceptableFileTypes, {
              types: acceptableHint,
            })} ${intl.formatMessage(messages.maxSize, { size: maxSizeStr })}`}
          </Typography>

          {errorNotice && (
            <Typography
              size="small"
              color={colorName.error}
              bottomMargin={16}
              textAlign="right"
            >
              {errorNotice}
            </Typography>
          )}
        </>
      )}
      {!!acceptedFiles.length && (
        <ConfirmUploadModal
          files={acceptedFiles}
          isModalOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          confirmUpload={() => confirmUpload(acceptedFiles)}
        />
      )}
    </>
  );
};

Dropzone.defaultProps = defaultPropTypes;
Dropzone.propTypes = propTypes;

export default Dropzone;
