import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import { ApiContext } from 'api';
import { renderField } from './renderField';
import { ROUTING } from 'routing';
import {
  MISCONDUCT_ALLOWED_FILETYPES,
  MISCONDUCT_MAX_FILE_SIZE,
  MISCONDUCT_MAX_FILES,
} from 'helpers/fileUploadsConstants';
import * as CONST from './constants';

import { updateFormData } from 'stores/misconductForm';
import {
  deleteFile,
  storeFiles,
  subscribeToUpdates,
  sendCurrentStepAndGetNextOne,
  sendCurrentStepAndGetPreviousOne,
  sendCurrentStepAndGetSummary,
  getSpecificPreviousStep,
} from 'stores/anonymousMisconductForm';
import { setFileLoading } from 'stores/me';

import FileUploader from 'components/FileUploader';
import Button from 'components/Button';
import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import DoubleColumnLayout from 'components/DoubleColumnLayout';
import ReportingColumn from './ReportingColumn';
import TokenModal from 'components/MisconductFormParts/TokenModal';
import ReportAddedModal from './ReportAddedModal';

import { colors, font } from 'styles';
import {
  QuestionGroup,
  StepsContainer,
  HeaderWrapper,
  SurveyNavigationBar,
  NavButtonsWrapper,
  BackSurveyArrowIcon,
  HintWrapper,
  Stepper,
  MainHeaderWrapper,
  HeaderLogo,
  InstructionButton,
} from './style';
import { messages } from './messages';
import { globalMessages, reportMessages } from 'translations';
import MarkdownParser from 'components/MarkdownParser';
import ReportSummary from './ReportSummary';
import SurveyArrowIcon from 'components/Svg/SurveyArrowIcon';
import ReportSteps from './ReportSteps';
import { CancelButton, PrimaryButton, SimpleModal } from 'components/SimpleModal';
import WarningOutlineIcon from 'components/Svg/WarningOutlineIcon';
import { useRecaptchaContext } from 'components/Recaptcha/UseRecaptchaContext';
import { InstructionModal } from 'components/InstructionModal';

const propTypes = {
  step: PropTypes.object.isRequired,
};

const StepsGenerator = ({ step }) => {
  // const desktopStyles = window.innerWidth > 767;
  const intl = useIntl();
  const { api } = useContext(ApiContext);
  const useRecaptcha = useRecaptchaContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { userLanguage } = useSelector((state) => state.auth);
  const isAnonymousReport = [ROUTING.REPORT_MISCONDUCT, ROUTING.SURVEYS_SURVEY].includes(
    path
  );
  const { currentStep, hasNextStep, formSummary, stepsCount } = useSelector(
    (state) => state.misconductForm
  );
  const { allowMisconductFileUploads } = useSelector((state) => state.tenantProperties);
  const { isLoading, privacyPolicyAccepted, customSidebarText, files, reportToken } =
    useSelector((state) => state.anonymousMisconductForm);
  const isLastPage = !hasNextStep;
  const [stepValid, setStepValid] = useState(false);
  const [summaryView, setSummaryView] = useState(false);
  const [isExitModalVisible, setIsExitModalVisible] = useState(false);
  const [isInstructionModalVisible, setIsInstructionModalVisible] = useState(false);
  const executeRecaptcha = useRecaptcha(userLanguage);

  useEffect(() => {
    const inputArray = step.sections
      .map((section) => section.inputs.map((input) => ({ ...input })))
      .flat();
    const requiredFields = inputArray.filter((item) => item.required);

    const validator = ({ type, answer }) => {
      switch (type) {
        case 'date':
          return answer.length > 0;
        case 'long-text':
        case 'short-text':
        case 'string':
          return answer !== '' && answer.length >= CONST.MIN_VALUE_LENGTH;
        default:
          return answer !== '';
      }
    };

    if (summaryView && isAnonymousReport) {
      setStepValid(requiredFields.every(validator) && privacyPolicyAccepted);
    } else {
      setStepValid(requiredFields.every(validator));
    }
  }, [
    currentStep,
    step,
    isLastPage,
    isAnonymousReport,
    privacyPolicyAccepted,
    summaryView,
  ]);

  useEffect(() => {
    if (formSummary !== null) setSummaryView(true);
  }, [formSummary]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [currentStep]);

  const deleteSelectedFile = (fileId) => {
    dispatch(deleteFile(fileId));
  };

  const addFiles = (files) => {
    files.map((file) => {
      return api.misconduct
        .uploadFile(file)
        .then((res) => {
          dispatch(storeFiles(res.data));
        })
        .catch(() => {})
        .finally(() => {
          dispatch(setFileLoading(false));
        });
    });
  };

  const goBack = () => {
    switch (currentStep) {
      case 1:
        return history.goBack();
      default:
        return dispatch(sendCurrentStepAndGetPreviousOne(api));
    }
  };

  const handleStepChange = () => {
    const validation = document.querySelector('#stepForm').reportValidity();
    if (isLastPage) {
      validation && dispatch(sendCurrentStepAndGetSummary(api));
    } else {
      validation && dispatch(sendCurrentStepAndGetNextOne(api));
    }
  };

  const handleDataChange = useCallback(
    ({ id, answer }) => dispatch(updateFormData(id, answer)),
    [dispatch]
  );

  const goToSpecificStep = (stepId) => {
    if (stepId) {
      dispatch(getSpecificPreviousStep(stepId, api)).then(setSummaryView(false));
    } else {
      setSummaryView(false);
    }
  };

  const handleSubscription = useCallback(
    async (email, fullname, phone) => {
      try {
        dispatch(
          subscribeToUpdates({
            email,
            fullname,
            phone,
            reportToken,
            executeRecaptcha,
          })
        );
      } catch (error) {
        // @todo handle error
      }
    },
    [reportToken, executeRecaptcha, dispatch]
  );

  const renderSubheader = (publicRoute) => {
    if (customSidebarText && publicRoute) {
      return (
        <MarkdownParser
          source={customSidebarText}
          fontSize={font.size.small}
          color={colors.lightGray}
        />
      );
    }

    return (
      <Typography
        className="mobile-hidden"
        size="small"
        color={colors.lightGray}
        bottomMargin={16}
      >
        {publicRoute
          ? intl.formatMessage(messages.anonymousReportingSubheader)
          : intl.formatMessage(messages.reportingSubheader)}
      </Typography>
    );
  };

  return (
    <>
      <DoubleColumnLayout
        isWhiteBackground
        bottomComponent={
          !summaryView && (
            <SurveyNavigationBar>
              {currentStep && stepsCount && (
                <Stepper currentStep={currentStep} totalSteps={stepsCount} />
              )}
              <div className="mobile-hidden">
                <Button
                  secondary
                  onClick={() => setIsExitModalVisible(true)}
                  isProcessing={isLoading}
                  data-testid="exit-button"
                >
                  {intl.formatMessage(globalMessages.exit)}
                </Button>
              </div>
              <div className="mobile-hidden">
                <ReportSteps />
              </div>
              <NavButtonsWrapper>
                <Button
                  secondary
                  isIcon
                  onClick={goBack}
                  isProcessing={isLoading}
                  data-testid="go-back-button"
                >
                  <BackSurveyArrowIcon />
                </Button>
                <Button
                  primary
                  isFluid
                  onClick={handleStepChange}
                  isProcessing={isLoading}
                  data-testid="step-button"
                  rightIcon={<SurveyArrowIcon />}
                  disabled={!stepValid}
                >
                  {isLastPage
                    ? intl.formatMessage(reportMessages.summaryButton)
                    : intl.formatMessage(globalMessages.nextStep)}
                </Button>
              </NavButtonsWrapper>
            </SurveyNavigationBar>
          )
        }
        columnComponent={
          <ReportingColumn
            header={
              isAnonymousReport
                ? intl.formatMessage(messages.anonymousReporting)
                : intl.formatMessage(messages.addingReport)
            }
            subheader={renderSubheader(isAnonymousReport)}
          />
        }
        hideColumnComponentOnMobile
      >
        <>
          <MainHeaderWrapper>
            <HeaderLogo isRestricted />

            <InstructionButton>
              <Button
                secondary
                onClick={() => setIsInstructionModalVisible(true)}
                data-testid="instruction-button"
              >
                {intl.formatMessage(globalMessages.instruction)}
              </Button>
            </InstructionButton>
          </MainHeaderWrapper>

          {currentStep === 1 && (
            <PageHeader
              label={
                isAnonymousReport
                  ? intl.formatMessage(messages.addNewComplain)
                  : intl.formatMessage(messages.addNewReport)
              }
            />
          )}
        </>
        {summaryView ? (
          <ReportSummary goToSpecificStep={goToSpecificStep} />
        ) : (
          <StepsContainer key={step.id} isActive>
            {!isLastPage && step.sections.length === 0 && !!step.description.length && (
              <HeaderWrapper>
                <HintWrapper>
                  <MarkdownParser
                    source={step.description}
                    fontSize={font.size.small}
                    color={colors.darkestGray}
                  />
                </HintWrapper>
              </HeaderWrapper>
            )}
            {step.sections.map((section) => (
              <div key={section.id}>
                <HeaderWrapper>
                  <Typography size="bigger" bottomMargin={12}>
                    {section.label}
                  </Typography>

                  {!!section.description.length && (
                    <HintWrapper>
                      <MarkdownParser
                        source={section.description}
                        fontSize={font.size.small}
                        color={colors.darkestGray}
                      />
                    </HintWrapper>
                  )}
                </HeaderWrapper>

                <form id="stepForm" onSubmit={handleStepChange}>
                  {section.inputs.map((input) => {
                    return (
                      <QuestionGroup key={input.id}>
                        {renderField({
                          option: input,
                          answer: input.answer,
                          onChange: handleDataChange,
                        })}
                      </QuestionGroup>
                    );
                  })}
                </form>
              </div>
            ))}

            {isLastPage && allowMisconductFileUploads && (
              <QuestionGroup>
                <HeaderWrapper>
                  <Typography size="huge" bottomMargin={8}>
                    {intl.formatMessage(messages.attachedFiles)}
                  </Typography>

                  {step.sections.length === 0 && !!step.description.length && (
                    <HintWrapper>
                      <MarkdownParser
                        source={step.description}
                        fontSize={font.size.small}
                        color={colors.darkestGray}
                      />
                    </HintWrapper>
                  )}
                </HeaderWrapper>

                <Typography size="small" bottomMargin={16} color={colors.black}>
                  <FormattedMessage
                    {...messages.attachedFilesTip}
                    values={{ br: <br /> }}
                  />
                </Typography>

                <form id="stepForm" onSubmit={handleStepChange}>
                  <FileUploader
                    maxNumOfFiles={MISCONDUCT_MAX_FILES}
                    filesProps={{
                      files: files,
                      deleteFile: deleteSelectedFile,
                      fileUrlResolver: api.misconduct.getWhistleblowerAttachementFile,
                    }}
                    dropzoneProps={{
                      fileTypes: MISCONDUCT_ALLOWED_FILETYPES,
                      acceptableHint: '.jpg, .png, .docx, .xls, .pdf, .msg, audio',
                      maxSize: MISCONDUCT_MAX_FILE_SIZE,
                      addFile: addFiles,
                      maxFilesOnDrop: 1,
                      maxFiles: MISCONDUCT_MAX_FILES,
                    }}
                  />
                </form>
              </QuestionGroup>
            )}
          </StepsContainer>
        )}

        {isExitModalVisible && (
          <SimpleModal
            isOpen={true}
            closeHandler={() => setIsExitModalVisible(false)}
            heading={intl.formatMessage(messages.exitModalHeading)}
            description={intl.formatMessage(messages.exitModalDescription)}
            icon={WarningOutlineIcon}
          >
            <CancelButton onClick={() => setIsExitModalVisible(false)} />

            <PrimaryButton onClick={() => history.push(ROUTING.INDEX)}>
              {intl.formatMessage(globalMessages.exit)}
            </PrimaryButton>
          </SimpleModal>
        )}

        {isInstructionModalVisible && (
          <InstructionModal
            isOpen={true}
            closeHandler={() => setIsInstructionModalVisible(false)}
            heading={intl.formatMessage(messages.exitModalHeading)}
            description={intl.formatMessage(messages.exitModalDescription)}
            icon={WarningOutlineIcon}
          >
            <ReportingColumn
              header={
                isAnonymousReport
                  ? intl.formatMessage(messages.anonymousReporting)
                  : intl.formatMessage(messages.addingReport)
              }
              subheader={renderSubheader(isAnonymousReport)}
            />
          </InstructionModal>
        )}

        {reportToken && isAnonymousReport && (
          <TokenModal token={reportToken} subscriptionHandler={handleSubscription} />
        )}
        {reportToken && !isAnonymousReport && <ReportAddedModal />}
      </DoubleColumnLayout>
    </>
  );
};

StepsGenerator.propTypes = propTypes;

export default StepsGenerator;
